import React from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as style from "../Supporters.module.css"
import Admiral from "./Admiral"
import RearAdmiral from "./RearAdmiral"
import Captain from "./Captain"

const Individuals = () => {
  const data = useStaticQuery(graphql`
    {
      viceAdmiral: file(relativePath: { eq: "vice-admiral@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      commander: file(relativePath: { eq: "commander@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 162, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ltcommander: file(relativePath: { eq: "ltCommander@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 162, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lieutenant: file(relativePath: { eq: "Lieutenant@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ensign: file(relativePath: { eq: "Ensign@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cpo: file(relativePath: { eq: "ChiefPettyOfficer.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      shipmate: file(relativePath: { eq: "shipmate.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="tab-content" style={{ paddingRight: "30px" }}>
      <Row className={`${style.blueRow} mb-4`} style={{ width: "100%" }}>
        <Col>
          <Row className="mb-2">
            <Col className="text-center">
              <Admiral />
              <h1 className="mt-2">Admiral - $25,000</h1>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={4} className="text-center my-2">
              <h4>David Sturdevant</h4>
              <h5>Hailey, Idaho</h5>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Row className={`${style.lightBlueRow} mb-4`}>
      <Col>
        <Row className="mb-2">
          <Col className="text-center">
          <NavySecretary />
          <h1 className="mt-2">Navy Secretary - $20,000</h1>
          </Col>
        </Row>
        <Row>
      <Col md={4} className="text-center my-2">
        <h4>John and Julie Jones</h4>
        <h5>Idaho Falls, Idaho</h5>
      </Col>
      <Col md={4} className="text-center my-2">
        <h4>Bob and Sue Smith</h4>
        <h5>Boise, Idaho</h5>
      </Col>
      <Col md={4} className="text-center my-2">
        <h4>John and Jane Doe</h4>
        <h5>Lewiston, Idaho</h5>
      </Col>
    </Row>        
      </Col>
      </Row>  */}

      <Row className={`${style.lightBlueRow} mb-4`}>
        <Col>
          <Row className="mb-2">
            <Col className="text-center">
              <Img
                fluid={data.viceAdmiral.childImageSharp.fluid}
                alt="Vice Admiral"
                style={{ maxWidth: "160px" }}
                className="d-block mx-auto"
              />
              <h1 className="mt-2">Vice Admiral - $10,000</h1>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="text-center my-2">
              <h5>
                RADM David Oliver
                <br />
                Linda Bithell Oliver Esq.
              </h5>
              <h4>Trinidad, CA</h4>
            </Col>
            <Col className="text-center my-2">
              <h5>Donald &amp; Merle Kay Hulse</h5>
              <h4>Pocatello, Idaho</h4>
            </Col>
            <Col className="text-center my-2">
              <h5>VADM Ronald and Patricia Eytchison</h5>
              <h4>Signal Mountain, TN</h4>
            </Col>
          </Row>
          <Row>
          <Col className="text-center my-2">
              <h5>Thomas and Elizabeth Tierney</h5>
              <h4>Hailey, Idaho</h4>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={`${style.blueRow} mb-4`}>
        <Col>
          <Row className="mb-2">
            <Col className="text-center">
              <RearAdmiral />
              <h1 className="mt-2">Rear Admiral - $5,000</h1>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={3} className="text-center my-2">
              <h5>Dennis and Kathy Johnson</h5>
              <h4>Eagle, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>Dr. Tom and Sue Thilo</h5>
              <h4>
                Hayden Lake, Idaho
                <br />
                In Memory of Robert C. Thilo
              </h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>Ray and Linda Stark</h5>
              <h4>Boise Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>RADM and Renee Coyle</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>Gregory McDonald</h5>
              <h4>Ketchum, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>R. Michael Murphy Trust</h5>
              <h4>Hailey, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>Fred and Erin Springman</h5>
              <h4>Idaho Falls, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>William Potter</h5>
              <h4>Hailey, Idaho</h4>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={`${style.lightBlueRow} mb-4`}>
        <Col>
          <Row className="mb-2">
            <Col className="text-center">
              <Captain />
              <h1 className="mt-2">Captain - $2,500</h1>
            </Col>
          </Row>
          <Row className="text-center">
            <Col md={3} className="text-center my-2">
              <h5>Todd and Letitia Williams</h5>
              <h4>Idaho Falls, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>Russell J. Johnson</h5>
              <h4>Idaho Falls, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>Gregory Randleman</h5>
              <h4>Fruitland, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>Don Mitchell</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>Scott and Mrs. Green</h5>
              <h4>Moscow, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>Warren and Robin Vest</h5>
              <h4>Coeur d'Alene, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>M.R. Baker</h5>
              <h4>Idaho Falls, Idaho</h4>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={`${style.blueRow} mb-4`}>
        <Col>
          <Row className="mb-2">
            <Col className="text-center">
              <Img
                fluid={data.commander.childImageSharp.fluid}
                alt="Commander"
                style={{ maxWidth: "100px" }}
                className="d-block mx-auto"
              />
              <h1 className="mt-2">Commander - $1,000</h1>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={3} className="text-center my-2 mb-4">
              <h5>Doug and Amber Armstrong</h5>
              <h4>Eagle, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2 mb-4">
              <h5>Donna and Chick Weaver</h5>
              <h4>Hayden Lake, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2 mb-4">
              <h5>Stewart Abercrombie Baker</h5>
              <h4>Great Falls, Virginia</h4>
            </Col>
            <Col md={3} className="text-center my-2 mb-4">
              <h5>Daniel S. Rowlands, CW5, (USA Retired)</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-2">
              <h5>Rich Young and Melanie Mitchell</h5>
              <h4>Meridian, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-4">
              <h5>Bill Previty</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-4">
              <h5>Bryne Crayne</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-4">
              <h5>Dick and Jennifer Raaz</h5>
              <h4>Idaho Falls, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-4">
              <h5>Walter &amp; Rita Donovan</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-4">
              <h5>James &amp; Lynn Johnston</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-4">
              <h5>Guttromson Family</h5>
              <h4>Coeur d'Alene, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-4">
              <h5>Dave the Firefighter</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-4">
              <h5>M.L. Lewis Jennings Trust</h5>
              <h4>Ketchum, Idaho</h4>
            </Col>
            <Col md={3} className="text-center my-4">
              <h5>Linda Fleetwood</h5>
              <h4>McCall, Idaho</h4>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={`${style.lightBlueRow} mb-4`}>
        <Col>
          <Row className="mb-2">
            <Col className="text-center">
              <Img
                fluid={data.lieutenant.childImageSharp.fluid}
                alt="Lieutenant"
                style={{ maxWidth: "100px" }}
                className="d-block mx-auto"
              />
              <h1 className="mt-2">Lieutenant - $500</h1>
            </Col>
          </Row>
          <Row>
            <Col className="text-center my-2">
              <h5>Mr. &amp; Mrs. Steven Arnold</h5>
              <h4>Nampa, Idaho</h4>
            </Col>

            <Col className="text-center my-2">
              <h5>Dave &amp; Nancy Leroy</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col className="text-center my-2">
              <h5>
                Major Doral (Butch) and
                <br />
                Connie McGee, USAF (ret)
              </h5>
              <h4>Coeur d' Alene, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Jack and Cindy Burdick</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Fred Hughes</h5>
              <h4>Iona, Idaho</h4>
            </Col>
          </Row>
          <Row>
            <Col className="text-center my-2">
              <h5>Craig Spencer and Jenny Alderden</h5>
              <h4>Boise, Idaho</h4>
            </Col>

            <Col className="text-center my-2">
              <h5>Leo Merrick</h5>
              <h4>Blackfoot, Idaho</h4>
            </Col>
            <Col className="text-center my-2">
              <h5>George &amp; Elsie Oyama</h5>
              <h4>Pocatello, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Frances Ashcroft</h5>
              <h4>Pocatello, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
        <h5>Trent Cadogan</h5>
          <h4>Caldwell, Idaho</h4>
      </Col>
          </Row>
        </Col>
      </Row>

      <Row className={`${style.blueRow} mb-4`}>
        <Col>
          <Row className="mb-2">
            <Col className="text-center">
              <Img
                fluid={data.ensign.childImageSharp.fluid}
                alt="Ensign"
                style={{ maxWidth: "50px" }}
                className="d-block mx-auto"
              />
              <h1 className="mt-2">Ensign - $250</h1>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="text-center my-2">
              <h5>CAPT and Mrs. Henry Netzer US Navy (ret)</h5>
              <h4>Hayden, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>R. Thomas Dale</h5>
              <h4>Nampa, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>John Swayze</h5>
              <h4>Filer, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Alan Griffitts</h5>
              <h4>Hayden, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Curt V. Casto</h5>
              <h4>Corona, California</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Donald L Netzer</h5>
              <h4>Sidney, Montana</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Frances Ashcroft</h5>
              <h4>Pocatello, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Christopher Guillet</h5>
              <h4>Worley, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Darlene and CDR (Ret) Chris Newcomb, USN</h5>
              <h4>Boulder City, Nevada</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Chelle Clements</h5>
              <h4>Eagle, Idaho</h4>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={`${style.lightBlueRow} mb-4`}>
        <Col>
          <Row className="mb-2">
            <Col className="text-center">
              <Img
                fluid={data.cpo.childImageSharp.fluid}
                alt="Chief Petty Officer"
                style={{ maxWidth: "100px" }}
                className="d-block mx-auto"
              />
              <h1 className="mt-2">Chief Petty Officer - $100</h1>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={2} className="text-center my-2">
              <h5>Bob Templeton, USN (ret)</h5>
              <h4>Va Beach, Virginia</h4>
            </Col>

            <Col md={2} className="text-center my-2">
              <h5>Bob and Sue Smith</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>George &amp; Melinda Schnarre</h5>
              <h4>Eagle, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>OSC (SW) Ed Mower</h5>
              <h4>San Diego, California</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Eric Motley</h5>
              <h4>Richmond, Virginia</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Christopher Roettgen &amp; Emily Gussenhoven</h5>
              <h4>Hayden Lake, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Kory &amp; Marni Wilson</h5>
              <h4>Coeur'd Alene, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Clay Bradfield</h5>
              <h4>McCall, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>James Purtee</h5>
              <h4>Coeur d' Alene, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Stephane and Philip Netzer</h5>
              <h4>Greenville, Wisconsin</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Sherri Nicholson</h5>
              <h4>Donnelly, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>John and Pamela Netzer</h5>
              <h4>Oshkosh, Wisconsin</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>James Purtee</h5>
              <h4>Couer d'Alene, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Damon and Jennifer Darakjy</h5>
              <h4>Couer d'Alene, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Mikel and Lorette Williams</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>MMM1 (SS) Ernest Loomis</h5>
              <h4>McCammon, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Robert J Terrien</h5>
              <h4>Post Falls, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Paul &amp; Cindy Agidius</h5>
              <h4>Moscow, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Tim and Diane Chandler</h5>
              <h4>Rathdrum, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>John Humphreys</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Steven Guzauskis</h5>
              <h4>Hayden, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Eric Motley</h5>
              <h4>Henrico, Virginia</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Shelley and Rodaney Dale</h5>
              <h4>Moscow, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Clay Barry</h5>
              <h4>Boise, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Tullio and Marylin Celano</h5>
              <h4>Ketchum, Idaho</h4>
            </Col>
            <Col md={2} className="text-center my-2">
              <h5>Paul Rekow MMCS(SS) Retired</h5>
              <h4>Lowman, Idaho</h4>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={`${style.lightBlueRow} mb-4`}>
        <Col>
          <Row className="mb-2">
            <Col className="text-center">
              <Img
                fluid={data.shipmate.childImageSharp.fluid}
                alt="Shipmate"
                style={{ maxWidth: "60px" }}
                className="d-block mx-auto"
              />
              <h1 className="mt-2">Shipmate &lt; $99</h1>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Individuals
